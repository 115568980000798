import axios, { AxiosRequestConfig, Canceler } from "axios";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getTokenFromCookies = (): string | null => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="));
  return token ? token.split("=")[1] : null;
};

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 10000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const currentPath = window.location.pathname;
      if (currentPath !== "/login") {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getTokenFromCookies();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Map to store pending requests
const pendingRequests = new Map<string, Canceler>();

// Function to generate a unique key for each request
const generateRequestKey = (config: AxiosRequestConfig): string => {
  const { url, method, params, data } = config;
  return `${method}-${url}-${JSON.stringify(params)}-${JSON.stringify(data)}`;
};

// Request Interceptor
axiosInstance.interceptors.request.use((config) => {
  const requestKey = generateRequestKey(config);

  // If a request with the same key is in progress, cancel it
  if (pendingRequests.has(requestKey)) {
    const cancel = pendingRequests.get(requestKey);
    cancel && cancel("Duplicate request canceled.");
    pendingRequests.delete(requestKey);
  }

  // Add a new CancelToken for this request
  config.cancelToken = new axios.CancelToken((cancel) => {
    pendingRequests.set(requestKey, cancel);
  });

  return config;
});

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const requestKey = generateRequestKey(response.config);
    // Remove completed requests from the pending map
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error:", error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
