// SearchBox.tsx
import React, { ChangeEvent, useState } from "react";
import { Input } from "@nextui-org/react";

interface SearchBoxProps {
  onSearch: (query: string) => void;
  filteredData: { subjectName: string }[]; // filteredData structure
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, filteredData }) => {
  const [query, setQuery] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value);
  };

  // matching results based on the query
  const hasResults =
    filteredData.length > 0 &&
    filteredData.some((card) =>
      card.subjectName.toLowerCase().includes(query.toLowerCase())
    );

  // Debugging logs
  console.log("Filtered Data:", filteredData);
  console.log("Current Query:", query);
  console.log("Has Results:", hasResults);

  return (
    <div>
      <Input
        id="search-box"
        type="text"
        placeholder="Search for topics"
        value={query}
        onChange={handleChange}
        className="mb-5"
      />
      {query && !hasResults && (
        <div className="text-red-500">No results found for your search.</div>
      )}
    </div>
  );
};

export default SearchBox;
