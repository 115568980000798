import Table from "../../components/ClassTable";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
const Classes = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  return (
    <div className="w-full flex flex-col min-h-screen items-center">
      <div className="w-full flex justify-end ml-auto my-5">
        <Button
          onPress={onOpen}
          color="primary"
          radius="none"
          className="rounded-xl text-white"
        >
          Create Class
        </Button>
        <Modal
          backdrop="opaque"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  Please provide details to create your class.
                </ModalHeader>
                <ModalBody>
                  <div className="space-y-4">
                    <Input
                      isClearable={true}
                      type="text"
                      isRequired
                      label="Name"
                      placeholder="Enter your Classname"
                      className=" rounded-lg"
                    />
                    <Input
                      isClearable={true}
                      type="text"
                      isRequired
                      label="Description"
                      placeholder="Enter your class description"
                      className=" rounded-lg"
                    />
                    <Input
                      isClearable={true}
                      type="text"
                      isRequired
                      label="Grade"
                      placeholder="Enter Grade level"
                      className=" rounded-lg"
                    />
                    <div className="flex flex-col">
                      <label className="text-sm font-semibold mb-1">
                        Thumbnail
                      </label>
                      <Input type="file" className=" rounded-lg" />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="rounded-xl w-full text-white bg-blue-600"
                  >
                    Create Class
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>

      <Table />
    </div>
  );
};
export default Classes;
