import React, { useState } from "react";
import { Button, Input } from "@nextui-org/react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Card, CardBody } from "@nextui-org/react";

const SecurityCard: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const [isVisibleCurrent, setIsVisibleCurrent] = useState<boolean>(false);
  const [isVisibleNew, setIsVisibleNew] = useState<boolean>(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState<boolean>(false);
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    // Handle password change logic here
    // For example, make an API call to update the password

    setError(null);
  };
  // const [isVisible, setIsVisible] = React.useState<boolean>(false);

  // Function to toggle visibility
  // const toggleVisibility = () => setIsVisible((prev) => !prev);

  return (
    <Card className="max-w-md w-full">
      <CardBody className="p-6">
        <div>
          <h2 className="text-2xl font-bold text-center mb-6">
            Change Password
          </h2>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Input
                label="Current Password"
                placeholder="Enter your password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => setIsVisibleCurrent((prev) => !prev)}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleCurrent ? (
                      <EyeOffIcon className="size-5 text-2xl pointer-events-none text-default-400" />
                    ) : (
                      <EyeIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleCurrent ? "text" : "password"}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <Input
                label="New Password"
                placeholder="Enter your new password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => setIsVisibleNew((prev) => !prev)}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleNew ? (
                      <EyeOffIcon className="size-5 text-2xl pointer-events-none text-default-400" />
                    ) : (
                      <EyeIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleNew ? "text" : "password"}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <Input
                label="Confirm New Password"
                placeholder="Enter your new password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={() => setIsVisibleConfirm((prev) => !prev)}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleConfirm ? (
                      <EyeOffIcon className="size-5 text-2xl pointer-events-none text-default-400" />
                    ) : (
                      <EyeIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleConfirm ? "text" : "password"}
                className="w-full"
              />
            </div>
            <Button className="w-full" type="submit" color="primary">
              Change Password
            </Button>
          </form>
        </div>
      </CardBody>
    </Card>
  );
};

export default SecurityCard;
