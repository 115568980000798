import { Button } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(10); // Start countdown from 10 seconds
  const mountRef = useRef<HTMLDivElement | null>(null);

  // Function to navigate to the dashboard
  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  // Automatically redirect after countdown reaches 0
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const timer = setTimeout(() => {
      handleGoToDashboard();
    }, 10000); // Redirect after 10 seconds

    // Cleanup both interval and timeout when component unmounts
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  // Three.js cube setup
  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );
    const renderer = new THREE.WebGLRenderer({ alpha: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    if (mountRef.current) {
      mountRef.current.appendChild(renderer.domElement);
    }

    // Cube geometry
    const geometry = new THREE.BoxGeometry(1, 1, 1); // Cube with width, height, depth

    // Transparent material for cube
    const material = new THREE.MeshStandardMaterial({
      color: 0x007bff,
      transparent: true,
      opacity: 0.3, // Semi-transparent effect
      depthWrite: false,
    });

    const cube = new THREE.Mesh(geometry, material);

    // Set initial position and add to scene
    cube.position.set(0, 0, 0); // Center the cube
    scene.add(cube);

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(1, 1, 1).normalize();
    scene.add(directionalLight);

    camera.position.z = 5;

    // Variables for random scale change
    let scaleDirection = 1; // 1 for increasing size, -1 for decreasing size
    let randomSwitchTime = Math.random() * 3000 + 2000; // Random time to reverse scale direction (2s-5s)
    const maxScale = Math.max(window.innerWidth, window.innerHeight) / 100; // Max scale relative to screen size
    const minScale = 0.5;
    const speed = 0.02; // Speed of scale change
    let lastSwitch = performance.now(); // Track when the scale direction was last switched

    // Variables to store mouse position
    let mouseX = 0;
    let mouseY = 0;

    // Event listener to capture mouse movement
    const onMouseMove = (event: MouseEvent) => {
      // Normalize mouse position between -1 and 1
      mouseX = (event.clientX / window.innerWidth) * 2 - 1;
      mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    window.addEventListener("mousemove", onMouseMove);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      const currentTime = performance.now();

      // Update rotation for a smooth spinning effect
      cube.rotation.x += 0.01; // Rotate around x-axis
      cube.rotation.y += 0.01; // Rotate around y-axis

      // Smoothly change size (scaling)
      cube.scale.x += speed * scaleDirection;
      cube.scale.y += speed * scaleDirection;
      cube.scale.z += speed * scaleDirection;

      // Reverse direction when reaching max or min size
      if (cube.scale.x >= maxScale || cube.scale.x <= minScale) {
        scaleDirection *= -1;
      }

      // Randomly switch scale direction after random intervals
      if (currentTime - lastSwitch >= randomSwitchTime) {
        scaleDirection *= -1; // Reverse the direction
        randomSwitchTime = Math.random() * 3000 + 2000; // Set a new random interval (2s-5s)
        lastSwitch = currentTime; // Update the last switch time
      }

      // Change cube's position slightly based on mouse movement
      cube.position.x = mouseX * 2; // Multiply by 2 to exaggerate movement
      cube.position.y = mouseY * 2;

      renderer.render(scene, camera);
    };

    animate();

    // Cleanup on unmount
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <div className="relative flex justify-center items-center h-screen overflow-hidden">
      <div ref={mountRef} className="absolute inset-0 -z-10" />

      <div className="text-center relative z-10">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          We’re still constructing this page
        </h1>
        <h2 className="text-3xl md:text-5xl font-normal mb-8">
          don’t worry, it’s a work in progress!
        </h2>
        <Button
          onClick={handleGoToDashboard}
          className="px-6 py-3"
          color="primary"
        >
          Visit Dashboard
        </Button>
        <p className="mt-4">
          Hang tight! Redirecting to the dashboard in {countdown} seconds...
          Just think of it as an exclusive preview!
        </p>
      </div>
    </div>
  );
};

export default Home;
