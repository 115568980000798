export const setNameInitials = (name: string = ""): string => {
  if (!name.trim()) return "👤"; // Return user icon for empty name

  const parts = name.trim().split(/\s+/); // Split by whitespace

  if (parts.length === 1) {
    // One-word name: return the first letter
    return parts[0].slice(0, 1).toUpperCase();
  } else if (parts.length === 2) {
    // Two-word name: return the first two letters of each word
    return (parts[0].slice(0, 1) + parts[1].slice(0, 1)).toUpperCase();
  } else {
    // More than two words: return the first two letters of the first and last words
    return (
      parts[0].slice(0, 1) + parts[parts.length - 1].slice(0, 1)
    ).toUpperCase();
  }
};
