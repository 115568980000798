import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Chapter from "../pages/Chapter";
import Explore from "../pages/Explore";
import Settings from "../pages/Settings";
import NavbarComponent from "../components/ui/NavbarComponent";
import Forgot from "../pages/ResetPassword";
import Footer from "../components/ui/Footer";
import { useAuth } from "../context/AuthContext";
import VerifyEmail from "../pages/VerifyEmail";
import ModelViewer from "../components/ModelViewer";
import Classes from "../pages/admin/Classes";

const ProtectedRoute = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    element: (
      <>
        <NavbarComponent />
      </>
    ),
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/view",
        element: (
          <ProtectedRoute>
            <ModelViewer />
          </ProtectedRoute>
        ),
      },
      {
        path: `/:subjectId/chapters`,
        element: <Chapter />,
      },
      {
        path: "/explore",
        element: <Explore />,
      },
      {
        path: "/reset-password",
        element: <Forgot />,
      },

      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        ),
      },
      {
        path: "/:Subject/chapters",
        element: <Chapter />,
      },

      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/admin/classes",
        element: <Classes />,
      },
    ],
  },
  {
    path: "/",
    element: <Home />,
  },
]);

function NotFoundPage(): JSX.Element {
  return <h1>404 - Page Not Found</h1>;
}

export default router;
