import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  CardFooter,
} from "@nextui-org/react";
import { ChapterCardProps } from "../../data/ChapterCardProps";
import ObjectCard from "./ObjectCard";
import axios from "../../config/axiosConfig";
import { Topic } from "../../data/TopicProps";
import Loader from "../ui/Loader";

const ChapterCard: React.FC<ChapterCardProps> = ({
  chapterId,
  chapterName,
  chapterDesc,
  thumb,
  chapterNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      const fetchTopics = async () => {
        try {
          const response = await axios.post("/topics/by-chapter", {
            chapterId: chapterId,
          });
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }

          setTopics(response.data);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchTopics();
    }
  }, [isOpen]);
  return (
    <>
      <Card
        style={{ cursor: "pointer" }}
        className="m-2 min-h-[110px]"
        onClick={openModal}
        isPressable
      >
        <CardBody
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <p>Chapter {chapterNumber}</p>
          <br />
          <div className="font-bold antialiased">{chapterName}</div>
          {chapterDesc && <div className="text-sm">{chapterDesc}</div>}
          {thumb && (
            <img
              src={thumb}
              alt={chapterName}
              className="card-img-right"
              style={{
                width: "90px",
                height: "auto",
                objectFit: "cover",
                marginLeft: "20px",
              }}
            />
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        isKeyboardDismissDisabled={false}
        backdrop="blur"
        size="5xl"
        scrollBehavior="inside"
        shadow="lg"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-center text-xl">
                <span className="font-medium">
                  Topics of chapter -{" "}
                  <span className="font-bold">{chapterName}</span>
                </span>
              </ModalHeader>
              <ModalBody>
                {loading && <Loader></Loader>}
                {error && <div>Error: {error}</div>}
                {!loading && !error && (
                  <>
                    {topics.length === 0 ? (
                      <div className="text-center">
                        No topics available for this chapter.
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                        {topics.map((topic) => (
                          <div key={topic.topicId}>
                            {/* Uncomment if you want to display the topic title */}
                            {/* <h5 className="text-lg font-bold mb-2">
        Topic - {topic.topicTitle}
      </h5> */}

                            {topic.topicObjects.map((object) => (
                              <ObjectCard
                                key={object.objectId}
                                object={object}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChapterCard;
