import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faGithub,
  faFacebook,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/edorbit-logo.png"; // Adjust the path as necessary

const Footer = () => {
  return (
    <footer className="flex w-full flex-col">
      <div className="mx-auto flex w-full max-w-7xl flex-col items-center justify-center px-6 py-12 lg:px-8">
        <div className="flex items-center justify-center">
          <img src={logo} alt="eodrbit logo" className="h-6 w-auto mr-2" />
          <span className="text-lg font-medium">edorbit</span>
        </div>
        <span
          aria-hidden="true"
          className="w-px h-px block"
          style={{ marginLeft: "0.25rem", marginTop: "1rem" }}
        />
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-1">
          <FooterLink href="#" text="Home" />
          <FooterLink href="#" text="About" />
          <FooterLink href="#" text="Services" />
          <FooterLink href="#" text="Projects" />
          <FooterLink href="#" text="Contact" />
          <FooterLink href="#" text="Blog" />
          <FooterLink href="#" text="Careers" />
        </div>
        <span
          aria-hidden="true"
          className="w-px h-px block"
          style={{ marginLeft: "0.25rem", marginTop: "1.5rem" }}
        />
        <div className="flex justify-center gap-x-5">
          <SocialLink href="#" icon={faFacebook} ariaLabel="Facebook" />
          <SocialLink href="#" icon={faInstagram} ariaLabel="Instagram" />
          <SocialLink href="#" icon={faTwitter} ariaLabel="Twitter" />
          <SocialLink href="#" icon={faGithub} ariaLabel="GitHub" />
          <SocialLink href="#" icon={faLinkedinIn} ariaLabel="LinkedIn" />
          <SocialLink href="#" icon={faYoutube} ariaLabel="YouTube" />
        </div>
        <div className="flex justify-center py-5 text-small text-default-500">
          <span>edorbit ❤ by Nbytech Immersive Pvt. Ltd.</span>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ href, text }: { href: string; text: string }) => (
  <a
    className="relative inline-flex items-center tap-highlight-transparent outline-none text-small no-underline hover:opacity-80 active:opacity-disabled transition-opacity text-default-500"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    tabIndex={0}
    role="link"
  >
    {text}
  </a>
);

const SocialLink = ({
  href,
  icon,
  ariaLabel,
}: {
  href: string;
  icon: any;
  ariaLabel: string;
}) => {
  return (
    <a
      className="relative inline-flex items-center tap-highlight-transparent outline-none hover:opacity-80"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={ariaLabel}
    >
      <FontAwesomeIcon icon={icon} className="text-lg" />
    </a>
  );
};

export default Footer;
