import { Card } from "@nextui-org/card";
import React from "react";

interface CardProps {
  imageUrl: string;
  title: string;
  viewCount: number;
}

const ExploreCard: React.FC<CardProps> = ({ imageUrl, title, viewCount }) => {
  return (
    <Card>
      <div className="rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer w-full h-full">
        <div className="h-3/4 w-auto rounded-t-lg overflow-hidden">
          <img
            src={imageUrl}
            alt={title}
            className="h-full w-auto object-contain m-auto"
          />
        </div>
        <div className="p-4 flex flex-col justify-between h-1/4">
          <h3 className="text-lg font-bold text-black">{title}</h3>
          <p className="text-black text-sm">{viewCount} views</p>
        </div>
      </div>
    </Card>
  );
};

const Explore: React.FC = () => {
  const cards: CardProps[] = [
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/mygs-256c6.appspot.com/o/thumbnail%2FEarth%20core-PhotoRoom.png-PhotoRoom.png?alt=media&token=a87aac1b-b737-4fcf-965f-46cf1c8fc9e9",
      title: "Card 1",
      viewCount: 561,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/mygs-256c6.appspot.com/o/thumbnail%2Fheart.png?alt=media&token=91bf4a2d-f26a-4eb7-abab-5bca0264ac6a",
      title: "Card 2",
      viewCount: 561,
    },
    {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/mygs-256c6.appspot.com/o/thumbnail%2Fskeleton1.png?alt=media&token=0e798c99-6305-4991-b51f-f2cd2e406cbd",
      title: "Card 3",
      viewCount: 562,
    },
    {
      imageUrl:
        "https://preview.redd.it/zfohxnf8t3pa1.jpg?width=1024&format=pjpg&auto=webp&v=enabled&s=0f660e0a56476991ee3b97f2885d8c010fec5b97",
      title: "Card 4",
      viewCount: 561,
    },
    {
      imageUrl:
        "https://preview.redd.it/zfohxnf8t3pa1.jpg?width=1024&format=pjpg&auto=webp&v=enabled&s=0f660e0a56476991ee3b97f2885d8c010fec5b97",
      title: "Card 5",
      viewCount: 561,
    },
    {
      imageUrl:
        "https://preview.redd.it/zfohxnf8t3pa1.jpg?width=1024&format=pjpg&auto=webp&v=enabled&s=0f660e0a56476991ee3b97f2885d8c010fec5b97",
      title: "Card 6",
      viewCount: 561,
    },
  ];

  return (
    <div className="bg-white text-black min-h-screen flex flex-col items-center justify-center px-4 sm:px-8 md:px-16">
      <div className="text-center mb-8 w-full">
        <h1 className="text-4xl font-bold text-black">Explore</h1>
        <p className="text-black">Welcome to the Explore section!</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 w-full">
        {cards.map((card, index) => (
          <div key={index} className="w-full h-80">
            <ExploreCard
              imageUrl={card.imageUrl}
              title={card.title}
              viewCount={card.viewCount}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Explore;
