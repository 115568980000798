import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Profile from "../components/cards/ProfileCard";
import { Card, CardBody, Tab, Tabs } from "@nextui-org/react";
import SecurityCard from "../components/cards/SecurityCard";

const Settings: React.FC = () => {
  let tabs = [
    {
      id: "profile",
      label: "Profile",
      content: <Profile />,
    },
    {
      id: "security",
      label: "Security",
      content: <SecurityCard />,
    },
  ];

  return (
    <>
      <div className="flex flex-col">
        <Tabs
          aria-label="Dynamic tabs"
          items={tabs}
          className="flex justify-center"
          key="primary"
          color="primary"
        >
          {(item) => (
            <Tab key={item.id} title={item.label}>
              <div className="flex justify-center items-center">
                {item.content}
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default Settings;
