import { Card, CardBody } from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SubjectCardProps } from "../../data/SubjectCardProps";

const SubjectCard: React.FC<SubjectCardProps> = ({
  subjectId,
  subjectName,
  subDesc,
  thumb,
  bgColor,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${subjectId}/chapters`);
  };

  return (
    <Card style={{ cursor: "pointer" }} className="m-2">
      <CardBody
        onClick={handleClick}
        className={`${bgColor}`}
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <h5 className="text-white font-bold text-3xl space-x-5 antialiased">
            {subjectName}
          </h5>
          <p className="text-white text-xl">{subDesc}</p>
        </div>
        {thumb && (
          <img
            src={thumb}
            alt=""
            className="card-img-right"
            style={{
              width: "90px",
              height: "auto",
              objectFit: "cover",
              marginLeft: "20px",
            }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SubjectCard;
