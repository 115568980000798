//This is the dashboard page, where user is redirect after login. It shows the subjects user can learn.
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "../config/axiosConfig";
import SubjectCard from "../components/cards/SubjectCard";
import Loader from "../components/ui/Loader";
import { useDisclosure } from "@nextui-org/react";
import SearchBox from "../components/ui/SearchBox";

const Dashboard: React.FC = () => {
  const [cardsData, setCardsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const getCurrentGreeting = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return "Good morning";
    } else if (hours >= 12 && hours < 16) {
      return "Good afternoon";
    } else if (hours >= 16 && hours < 22) {
      return "Good evening";
    } else {
      return "Your bed awaits";
    }
  };

  const greeting = getCurrentGreeting();
  const isFetching = useRef(false);

  const fetchData = async () => {
    if (isFetching.current) {
      // If fetch is already in progress, do nothing
      return;
    }

    isFetching.current = true;
    try {
      const response = await axios.post("/subjects/by-class", {
        classId: 10,
      });

      setCardsData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      isFetching.current = false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (query: string) => {
    if (!query) {
      setFilteredData(cardsData);
    } else {
      const filtered = cardsData.filter((card) =>
        card.subjectName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const bgColors = [
    "bg-gradient-to-r from-teal-300 to-cyan-600",
    "bg-gradient-to-r from-pink-300 to-rose-600",
    "bg-gradient-to-r from-amber-300 to-orange-400",
  ];

  return (
    <div>
      <div className="container mt-2">
        <h2 className="text-left mb-4 text-5xl">
          {greeting},<span className="text-teal-500 text-5xl"> Learner</span>
        </h2>
        <h2 className="text-left mb-6 text-2xl">
          What would you like to learn today?
        </h2>

        {/* Search Box */}
        <SearchBox onSearch={handleSearch} filteredData={[]} />

        <div className="row justify-content-center grid sm:grid-cols-2 gap-4 lg:grid-cols-3">
          {filteredData.map(
            (
              card: {
                subjectId: number;
                subjectName: string;
                subjectDescription: string;
                subjectThumbnailURL: string;
              },
              index: number
            ) => (
              <SubjectCard
                subjectId={card.subjectId}
                subjectName={card.subjectName}
                subDesc={card.subjectDescription}
                thumb={card.subjectThumbnailURL}
                bgColor={bgColors[index % bgColors.length]}
              />
            )
          )}
        </div>

        <div className="text-center mt-8">
          <Link to="/explore">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Explore
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
function setFilteredData(data: any) {
  throw new Error("Function not implemented.");
}
