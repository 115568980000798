import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { MailIcon as HeroMailIcon } from "@heroicons/react/solid";
import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import toast from "react-hot-toast";
import { login } from "../service/AuthService";
import baseURL from "../config/axiosConfig";

const Login: React.FC = () => {
  const [emailMobile, setEmailMobile] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onOpenChange: onSuccessOpenChange,
  } = useDisclosure();

  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState<string>("");
  const [resetEmailError, setResetEmailError] = useState<string>("");
  const [isInValidEmail, setIsInValidEmail] = useState<boolean>(false);
  const navigate = useNavigate();

  const auth = useAuth();
  const setIsAuthenticated = auth?.setIsAuthenticated ?? (() => {});
  const isAuthenticated = auth?.isAuthenticated ?? false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
      toast.error("Already Logged In", {
        duration: 4000,
        position: "bottom-center",
      });
    }
  }, [isAuthenticated, navigate]);

  const handleEmailMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailMobile(e.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid = true;

    if (isValid) {
      setIsSubmitting(true);
      try {
        const data = await login(emailMobile, password);

        if (data && data.token) {
          const { token, tokenType } = data;
          const decodedToken = JSON.parse(atob(token.split(".")[1]));
          const expiryDate = new Date(decodedToken.exp * 1000).toUTCString();
          document.cookie = `token=${token}; expires=${expiryDate}; path=/`;
          document.cookie = `tokenType=${tokenType}; expires=${expiryDate}; path=/`;
          setIsAuthenticated(true);
          toast.success("Login successful!", {
            duration: 4000,
            position: "bottom-center",
          });
          navigate("/dashboard");
        } else {
          setIsAuthenticated(false);
          setLoginError("Something went wrong. Please try again.");
        }
      } catch (error) {
        if (error instanceof Error) {
          setLoginError(error.message);
        } else {
          setLoginError("An unexpected error occurred.");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleSendVerification = async () => {
    if (!isValidEmail(resetEmail)) {
      setIsInValidEmail(true);
      setResetEmailError("Please enter a valid email address.");
      return;
    }
    setIsSubmittingEmail(true);
    try {
      const emailres = await baseURL.post("/auth/forget-password", {
        email: resetEmail,
      });
      console.log(emailres);

      if (emailres.data.statusCode === 200) {
        setResetEmailError("");
        onOpenChange(); // Close the reset modal
        onSuccessOpen(); // Open the success modal
        toast.success(emailres.data.message, {
          duration: 4000,
          position: "bottom-center",
        });
      }
    } catch (e: any) {
      console.error("Error response:", e.response);
      setIsInValidEmail(true);
      if (e.response) {
        setResetEmailError(e.response.data.message || "Invalid Credential");
      } else {
        setResetEmailError("Something went wrong. Please try again.");
      }
    } finally {
      setIsSubmittingEmail(false);
    }
  };

  const handleResetEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setResetEmail(e.target.value);
    setResetEmailError("");
  };

  const isValidEmail = (email: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const handleResetModalOpen = () => {
    setResetEmail("");
    onOpen();
  };

  const isInvalid = React.useMemo(() => {
    setIsInValidEmail(false);
    if (resetEmail === "") {
      setIsInValidEmail(false);
      return false;
    }
    if (resetEmailError) {
      setIsInValidEmail(true);
      return true;
    }
    return isValidEmail(resetEmail) ? false : true;
  }, [resetEmail]);

  return (
    <div className="flex items-center justify-center px-5 lg:px-0">
      <div
        className={`max-w-screen-xl bg-white border shadow sm:rounded-lg flex justify-center flex-1 `}
      >
        <div className="flex-1 bg-blue-900 text-center hidden md:flex">
          <div
            className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage:
                "url(https://www.tailwindtap.com/assets/common/marketing.svg)",
            }}
          ></div>
        </div>
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div className=" flex flex-col items-center">
            <div className="text-center">
              <h1 className="text-2xl xl:text-4xl font-extrabold text-blue-900">
                User Login
              </h1>
              <p className="text-[12px] text-gray-500">
                Hey enter your login credentials here.
              </p>
            </div>
            <div className="w-full flex-1 mt-8">
              <form onSubmit={handleSubmit} style={{ alignItems: "center" }}>
                <div className="mb-3 mx-auto max-w-xs flex flex-col gap-4">
                  <input
                    type="text"
                    className={`w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${
                      emailError ? "border-red-500" : ""
                    }`}
                    id="emailMobile"
                    placeholder="Enter your Email/Mobile no."
                    value={emailMobile}
                    onChange={handleEmailMobileChange}
                    required
                  />
                  {emailError && (
                    <div className="text-red-500 text-sm">{emailError}</div>
                  )}
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ${
                        passwordError ? "border-red-500" : ""
                      }`}
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    {passwordError && (
                      <div className="text-red-500 text-sm">
                        {passwordError}
                      </div>
                    )}
                    {loginError && (
                      <div className="text-red-500 text-sm mt-1 text-center">
                        {loginError}
                      </div>
                    )}
                    <button
                      type="submit"
                      className={`mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Logging in..." : "Login"}
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="mt-6 text-xs text-gray-600 flex items-center justify-center">
                    <Button
                      onPress={handleResetModalOpen}
                      color="primary"
                      radius="none"
                      className="bg-transparent text-blue-500"
                    >
                      Forgot Password
                    </Button>
                    <Modal
                      backdrop="opaque"
                      isOpen={isOpen}
                      onOpenChange={onOpenChange}
                      placement="top-center"
                    >
                      <ModalContent>
                        {(onClose) => (
                          <>
                            <ModalHeader className="flex flex-col gap-1">
                              Forget Password
                            </ModalHeader>
                            <ModalBody>
                              <p className=" text-sm text-gray-600  font-bold"></p>
                              <Input
                                isClearable={true}
                                type="email"
                                isRequired
                                label=" Email"
                                startContent={
                                  <HeroMailIcon className="size-5 text-gray-600" />
                                }
                                placeholder="Enter your registered email"
                                value={resetEmail}
                                onChange={handleResetEmailChange}
                                isInvalid={isInValidEmail}
                                errorMessage={`${
                                  resetEmailError
                                    ? resetEmailError
                                    : "Please enter a valid email address"
                                }`}
                              />
                              {/* {resetEmailError && (
                                <div className="text-red-500 text-sm mt-2">
                                  {resetEmailError}
                                </div>
                              )} */}
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="danger"
                                variant="flat"
                                onPress={onClose}
                              >
                                Close
                              </Button>
                              <Button
                                color="primary"
                                onPress={handleSendVerification}
                                disabled={!isValidEmail(resetEmail)}
                                className={`${
                                  isSubmittingEmail
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                              >
                                {isSubmittingEmail
                                  ? " Sending Please Wait .... "
                                  : "Send Verification Email"}
                              </Button>
                            </ModalFooter>
                          </>
                        )}
                      </ModalContent>
                    </Modal>

                    <Modal
                      isOpen={isSuccessOpen}
                      onOpenChange={onSuccessOpenChange}
                      placement="top-center"
                    >
                      <ModalContent className="px-12 py-10 max-w-lg [scrollbar-width:none]">
                        {(onClose) => (
                          <>
                            <ModalBody className="text-center w-fit">
                              <img
                                src="/success.png"
                                alt="Success"
                                className="mx-auto mb-4 size-40"
                              />
                              <p className="font-semibold text-base text-black w-full text-nowrap">
                                Your verification email has been sent
                                successfully.
                                <br /> Please check your inbox to continue.
                              </p>
                            </ModalBody>
                          </>
                        )}
                      </ModalContent>
                    </Modal>
                  </div>
                  <div className="text-xs text-gray-600 flex justify-center">
                    <p className="text-sm text-gray-600 mt-6 text-center">
                      Don't have an account? {"  "}
                      <NavLink
                        to="/signup"
                        className="text-blue-500 font-medium hover:underline"
                      >
                        Sign up
                      </NavLink>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
