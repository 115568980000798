import React from "react";

import { Html } from "@react-three/drei";
import { Spinner } from "@nextui-org/react";
import Loader from "./Loader";

const LoaderWrapper: React.FC = () => {
  return (
    <Html center>
      <Loader />
    </Html>
  );
};

export default LoaderWrapper;
