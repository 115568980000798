import { useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tab,
} from "@nextui-org/react";
import baseURL from "../config/axiosConfig";
interface ClassRowData {
  classId: number;
  className: string;
  classDescription: string;
  gradeLevel: string;
}

export default function App() {
  const [rowData, setRowData] = useState<ClassRowData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await baseURL.get("/classes");

        setRowData(response.data);
      } catch (error) {
        console.error(
          "Error fetching data:",
          (error as any).response
            ? (error as any).response.data
            : (error as any).message,
        );
      }
    };

    fetchData();
  }, []);
  return (
    <Table isStriped aria-label="All classes">
      <TableHeader>
        <TableColumn>CLASS ID</TableColumn>
        <TableColumn>NAME</TableColumn>
        <TableColumn>DESCRIPTION</TableColumn>
        <TableColumn>GRADE</TableColumn>
      </TableHeader>

      {rowData.length > 0 ? (
        <TableBody>
          {rowData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.classId}</TableCell>
              <TableCell>{row.className}</TableCell>
              <TableCell>{row.classDescription}</TableCell>
              <TableCell>{row.gradeLevel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <TableBody emptyContent={"No data to display."}>{[]}</TableBody>
      )}
    </Table>
  );
}
