import React, { useEffect, useState } from "react";
import { Button, Input, Card, CardBody } from "@nextui-org/react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import baseURL from "../config/axiosConfig";
import axios from "axios";

export default function ResetPassword() {
  const [isVisible, setIsVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const Reset_token = searchParams.get("t");

  useEffect(() => {
    const validateToken = async () => {
      if (!Reset_token) {
        toast.error("Not Authorized", {
          duration: 4000,
          position: "bottom-center",
        });
        navigate("/login");
        return;
      }

      try {
        const response = await baseURL.get(
          `/auth/validate-token?t=${Reset_token}`,
        );

        if (response.data.statusCode === 200 && response.data.success) {
          toast.success(response.data.message, {
            duration: 4000,
            position: "bottom-center",
          });
        } else {
          toast.error(response.data.message, {
            duration: 4000,
            position: "bottom-center",
          });
          navigate("/login");
        }
      } catch (error: any) {
        console.error("Error validating token:", error.response || error);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong. Please try again.",
          {
            duration: 4000,
            position: "bottom-center",
          },
        );
        navigate("/login");
      }
    };

    validateToken();
  }, [Reset_token, navigate]);

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setErrorMessage(""); // Clear error message when user types
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setErrorMessage(""); // Clear error message when user types
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setIsSubmitting(true);
      try {
        const newpassres = await baseURL.post(
          `/auth/reset-password?t=${Reset_token}`,
          {
            newPassword: password,
            confirmPassword: confirmPassword,
          },
        );

        if (newpassres.data.statusCode === 200) {
          toast.success(newpassres.data.message, {
            duration: 4000,
            position: "bottom-center",
          });
          navigate("/login");
        } else {
          toast.error("Something went wrong. Please try again.", {
            duration: 4000,
            position: "bottom-center",
          });
        }
      } catch (e: any) {
        console.error("Error response:", e.response);
        if (e.response) {
          toast.error(e.response.data.message || "Something Went Wrong", {
            duration: 4000,
            position: "bottom-center",
          });
        } else {
          toast.error("Something went wrong. Please try again.", {
            duration: 4000,
            position: "bottom-center",
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="flex justify-center items-center">
      <Card className="max-w-md w-full">
        <CardBody className="p-6">
          <h2 className="text-2xl font-bold text-gray-800 text-center">
            Reset Password
          </h2>
          <div className="flex flex-col items-center justify-center mt-2">
            <img src="/success.png" className="size-10" alt="success" />
            <p className="text-black">
              Your email has been verified successfully.
            </p>
          </div>
          <h2 className="text-sm font-bold text-gray-800 text-center">
            Please provide a new password to reset your password.
          </h2>
          <form className="mt-6" onSubmit={handleSubmit}>
            <div className="mb-4">
              {/* <label className="block text-sm font-medium text-gray-700 mb-3">New Password</label> */}
              <Input
                placeholder="Enter your password"
                label="New Password"
                isRequired={true}
                endContent={
                  <button
                    className="focus:outline-none "
                    type="button"
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeOffIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                className="max-w-lg"
              />
            </div>
            <div className="mb-4">
              {/* <label className="block text-sm font-medium text-gray-700 mb-3">Confirm Password</label> */}
              <Input
                placeholder="Enter your password"
                label="Confirm Password"
                isRequired={true}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeOffIcon className="size-5 text-2xl pointer-events-none text-default-400" />
                    ) : (
                      <EyeIcon className="size-5 text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className="max-w-lg"
              />
            </div>
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
            <div className="mt-6">
              <Button
                color="primary"
                radius="none"
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900
                  ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? "Please Wait..." : "Reset Password"}
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
