import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { NextUIProvider } from "@nextui-org/react";
import { Toaster } from "react-hot-toast";
import { ThemeProvider as NextThemesProvider } from "next-themes";

const root = ReactDOM.createRoot(
  document.getElementById("edorbit-root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Toaster />
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="light">
        <App />
      </NextThemesProvider>
    </NextUIProvider>
  </React.StrictMode>
);

reportWebVitals();
