import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useAnimations, useGLTF } from "@react-three/drei";
import { AnimationAction, Box3, Vector3 } from "three";
import LoaderWrapper from "./ui/LoaderWrapper";
import { ModelProps } from "../data/ModelProps";
import { Button } from "@nextui-org/react";
import { InformationCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { ObjectCardProps, TopicObject } from "../data/TopicProps";
import { useLocation } from "react-router-dom";
import axios from "../config/axiosConfig";
import Loader from "./ui/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faExpand,
  faCompress,
  faPlay,
  faPause,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";

const Model: React.FC<ModelProps> = ({
  play,
  rotationSpeed,
  onModelLoaded,
  modelUrl,
  setHasAnimations,
}) => {
  const { scene, animations } = useGLTF(modelUrl) as any;
  const { ref, mixer } = useAnimations(animations);
  const currentAction = useRef<AnimationAction | null>(null);

  useEffect(() => {
    onModelLoaded();
    setHasAnimations(Array.isArray(animations) && animations.length > 0);
    if (play) {
      if (Array.isArray(animations) && animations.length > 0) {
        if (!currentAction.current) {
          currentAction.current = mixer.clipAction(animations[0]);
        }
        currentAction.current.paused = false;
        currentAction.current.play();
      }
    } else {
      if (currentAction.current) {
        currentAction.current.paused = true;
      }
    }
  }, [play, mixer, animations, onModelLoaded, setHasAnimations]);

  useEffect(() => {
    const boundingBox = new Box3().setFromObject(scene);
    const center = new Vector3();
    boundingBox.getCenter(center);

    const size = new Vector3();
    boundingBox.getSize(size);
    const maxDimension = Math.max(size.x, size.y, size.z);
    const desiredMaxDimension = 50;
    const scale = desiredMaxDimension / maxDimension;
    scene.scale.set(scale, scale, scale);

    const offsetX = center.x * scale;
    const offsetY = center.y * scale;
    const offsetZ = center.z * scale;
    scene.position.set(-offsetX, -offsetY, -offsetZ);
  }, [scene]);

  useFrame(() => {
    if (rotationSpeed > 0) {
      scene.rotation.y += rotationSpeed;
    }
  });

  return <primitive ref={ref} object={scene} />;
};

const ModelViewer: React.FC = () => {
  const [objectId, setObjectId] = useState<string | null>(null);
  const location = useLocation();
  const [object, setObjectData] = useState<TopicObject | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [play, setPlay] = useState(false);
  const [rotationSpeed, setRotationSpeed] = useState(0);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [hasAnimations, setHasAnimations] = useState(false);
  const [rotateIcon, setRotateIcon] = useState("/360-degrees.png");
  const [canvasHeight, setCanvasHeight] = useState(
    window.innerHeight - 60 - 40
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const [buttonColor, setButtonColor] = useState<"default" | "primary">(
    "default"
  );

  const cache = {};
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("i");
    setObjectId(id);
  }, []);

  // useEffect(() => {
  //   const fetchObjectData = async () => {
  //     if (objectId) {
  //       setLoading(true);
  //       try {
  //         const response = await axios.get(`/objects/${objectId}`);
  //         if (response.status !== 200) {
  //           throw new Error("Network response was not ok");
  //         }
  //         const data: TopicObject = response.data;
  //         setObjectData(data);
  //       } catch (error) {
  //         if (error instanceof Error) {
  //           setError(error.message);
  //         } else {
  //           setError("An unknown error occurred");
  //         }
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   fetchObjectData();
  // }, [objectId]);
  const isFetching = useRef(false); // Ref to track if API call is in progress

  useEffect(() => {
    const fetchObjectData = async () => {
      if (objectId && !isFetching.current) {
        isFetching.current = true; // Mark fetching as started
        setLoading(true);

        try {
          const response = await axios.get(`/objects/${objectId}`);
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }

          const data = response.data;
          setObjectData(data);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message);
          } else {
            setError("An unknown error occurred");
          }
        } finally {
          setLoading(false);
          isFetching.current = false; // Mark fetching as finished
        }
      }
    };

    fetchObjectData();
  }, [objectId]);

  useEffect(() => {
    const handleResize = () => {
      setCanvasHeight(window.innerHeight - 60 - 40);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePlayPause = () => {
    setPlay(!play);
  };

  const toggleRotation = () => {
    toggleButtonColor();
    setRotationSpeed(rotationSpeed > 0 ? 0 : 0.01);
  };
  const toggleButtonColor = () => {
    setButtonColor((prevColor) =>
      prevColor === "default" ? "primary" : "default"
    );
  };

  const handleModelLoaded = () => {
    setModelLoaded(true);
  };

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleBackClick = () => {
    setShowInfo(false);
  };

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
      setIsVisible(false);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsVisible(true);
    }
    setIsFullscreen(!isFullscreen);
  };
  const handleLike = () => {
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
  };

  if (loading) {
    return <Loader></Loader>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!object) {
    return <div>No object data available</div>;
  }

  return (
    <div className={`relative ${isFullscreen ? "w-full h-full" : ""}`}>
      <div
        style={{
          width: showInfo ? "50%" : "auto",
          height: `${canvasHeight}px`,
          overflow: "hidden",
          float: showInfo ? "left" : "none",
        }}
      >
        <Canvas
          camera={{ fov: 70, position: [0, 0, 50], near: 0.1, far: 1000 }}
        >
          <Suspense fallback={<LoaderWrapper />}>
            <ambientLight />
            <Model
              play={play}
              rotationSpeed={rotationSpeed}
              onModelLoaded={handleModelLoaded}
              modelUrl={object.objectURL}
              setHasAnimations={setHasAnimations}
            />
            <OrbitControls />
          </Suspense>
        </Canvas>
      </div>
      {showInfo && (
        <div className="absolute right-0 top-0 w-1/2 h-full bg-gray-100 p-4">
          <Button
            isIconOnly
            color="danger"
            onClick={handleBackClick}
            className="px-2 py-2 absolute right-0 top-0 text-white rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <XCircleIcon />
          </Button>
          <p>
            <strong>Name:</strong> {object.objectName}
          </p>
          <p>
            <strong>Description:</strong> {object.objectDescription}
          </p>
          <p>
            <strong>View Count:</strong> {object.viewCount}
          </p>
          <p>
            <strong>Upload Date:</strong> {object.uploadDate}
          </p>
        </div>
      )}
      {modelLoaded && !showInfo && (
        <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex justify-center gap-4">
          {hasAnimations && (
            <Button
              isIconOnly
              variant="shadow"
              onClick={handlePlayPause}
              className="h-auto w-auto flex flex-col p-3"
            >
              {play ? (
                <FontAwesomeIcon icon={faPause} className="text-lg" />
              ) : (
                <FontAwesomeIcon icon={faPlay} className="text-lg" />
              )}
            </Button>
          )}
          <Button
            isIconOnly
            onClick={toggleRotation}
            variant="shadow"
            color={buttonColor}
            className="h-auto w-auto flex flex-col p-3"
          >
            <FontAwesomeIcon icon={faRotate} className="text-xl" />
          </Button>
          <Button
            isIconOnly
            onClick={handleFullscreenToggle}
            variant="shadow"
            className="h-auto w-auto flex flex-col p-3"
          >
            {isFullscreen ? (
              <FontAwesomeIcon icon={faCompress} className="text-xl" />
            ) : (
              <FontAwesomeIcon icon={faExpand} className="text-xl" />
            )}
          </Button>
        </div>
      )}
      {modelLoaded && !showInfo && (
        <>
          <div className="absolute right-0 top-2 flex flex-col items-center">
            <Button
              isIconOnly
              variant="shadow"
              onClick={handleInfoClick}
              className="h-auto w-auto flex flex-col p-1 mb-2"
            >
              <InformationCircleIcon />
            </Button>

            <Button
              isIconOnly
              onClick={handleLike}
              variant="shadow"
              className="h-auto w-auto flex flex-col p-1 mb-2"
            >
              <img
                src={isLiked ? "/liked.png" : "/unlike.png"}
                alt=""
                className="w-6 h-6"
              />
              <span className="text-center mt-1">{likes}</span>
            </Button>

            <Button
              isIconOnly
              variant="shadow"
              className="h-auto w-auto flex flex-col p-1"
            >
              <FontAwesomeIcon icon={faEye} />
              <span className="text-center mt-1">{object.viewCount}</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ModelViewer;
