import React from "react";

import { Html } from "@react-three/drei";
import { Spinner } from "@nextui-org/react";

const Loader: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <Spinner label="Loading..." color="primary" labelColor="primary" />
    </div>
  );
};

export default Loader;
